module.exports = {
  siteTitle: 'Comercia Tu Producto', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#657db9',
  manifestThemeColor: '#657db9',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-identity/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Martha Viridiana Borbolla Huitrón',
  heading: 'Ejecutiva de Ventas',
  text: 'Contáctame:',
  // social
  socialLinks: [
    {
      icon: 'fa-whatsapp',
      name: 'Celular',
      url:
        'https://wa.me/+5215551449600/?text=Hola Marhta, me interesa saber de tu oferta en productos farmacéuticos',
    } /*
    {
      icon: 'fa-globe',
      name: 'WebPage',
      url: 'https://www.comerciatp.com/',
    },*/,
    {
      icon: 'fa-address-card',
      name: 'Tarjeta de direcciones',
      url:
        'https://res.cloudinary.com/farmazone/raw/upload/v1629525021/vcards/MarthaBorbolla.vcf',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:martitha33rocc@gmail.com',
    } /* 
    { 
      icon: 'fa-linkedin',
      name: 'linkedin',
      url: 'http://linkedin.com/in/abner-sánchez-fiz-a8018a105'
    },*/,
  ],
};
